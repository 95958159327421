import { graphql, useGraphql } from "@internal/gql/client";
import { useRouter } from "next/router";
import { useState } from "react";
import { useAuth } from "services/auth/AuthProvider";
import { seconds } from "utility/seconds";

export function usePortfolioDeals() {
  const router = useRouter();
  const { account } = useAuth();

  const { data, isSuccess, error } = useGraphql(portfolioDealsQuery, {
    variables: {
      membersInput: {
        roles: ["LEAD"],
      },
    },
    options: {
      staleTime: seconds(5),
    },
  });

  // https://tanstack.com/table/latest/docs/guide/data#give-data-a-stable-reference
  const [stableFallbackArray] = useState<
    NonNullable<typeof data>["portfolioDeals"]
  >([]);

  return {
    ready: isSuccess && router.isReady,
    error,
    data: data?.portfolioDeals ?? stableFallbackArray,
    accountId: account?.id ?? "",
  };
}

export const portfolioDealsQuery = graphql(`
  query GetPortfolioDeals($membersInput: MembersInput!) {
    portfolioDeals {
      __typename
      ... on Deal {
        id
        tradeName
        closedDate
        status
        slug
        logoUrl

        ... on Primary {
          instrument {
            __typename
          }
        }

        ... on Secondary {
          instrument {
            __typename
          }
        }

        syndicate {
          __typename
          id
          slug
          members(input: $membersInput) {
            __typename
            members {
              id
              role
              account {
                __typename
                id
              }
            }
          }
        }
        documents {
          ... on DealInvestorDocuments {
            __typename
            dealSheetDocument
            proofOfInvestmentDocument
            syndicateTermsDocument
            investmentAgreementDocument
            platformAgreementDocument
          }
          ... on DealLeadDocuments {
            __typename
            defaultDealSheet
            dealSheetDocument
            syndicateTermsDocument
            investmentAgreementDocument
            platformAgreementDocument
          }
        }
        investment {
          __typename
          id
          finalInvestmentAmount {
            __typename
            formatted
            amount
          }
        }
        lead {
          ... on Syndicate {
            __typename
            id
            displayName
          }
          ... on Founder {
            __typename
            id
            displayName
          }
        }
      }
    }
  }
`);
