import { useGraphql } from "@internal/gql/client";
import { createTypedTabs } from "components/main/Tabs";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { createdDealsQuery } from "./components/CreatedDeals/useCreatedDeals";
import { invitedDealsQuery } from "./components/InvitedDeals/useInvitedDeals";
import { passedDealsQuery } from "./components/PassedDeals/usePassedDeals";
import { portfolioDealsQuery } from "./components/PortfolioDeals/usePortfolioDeals";

export enum TabValue {
  Created = "created",
  Invited = "invited",
  Portfolio = "portfolio",
  Passed = "passed",
}

export const dealTabs = createTypedTabs([
  TabValue.Created,
  TabValue.Invited,
  TabValue.Portfolio,
  TabValue.Passed,
]);

export function useDealsScreen() {
  const router = useRouter();
  const currentTab = router.query["tab"] as TabValue | undefined;
  const routerReady = router.isReady;
  const queryEnabled = !currentTab;

  // prefetch(s)
  useGraphql(passedDealsQuery);
  useGraphql(invitedDealsQuery);
  useGraphql(portfolioDealsQuery, {
    variables: {
      membersInput: {
        roles: ["LEAD"],
      },
    },
  });

  const { isSuccess, error, data } = useGraphql(createdDealsQuery, {
    options: {
      staleTime: 5000,
      enabled: queryEnabled,
    },
  });

  const createdDeals = data?.createdDeals ?? [];

  // If the user does not have created deals, auto-select the invited tab
  useEffect(() => {
    if (isSuccess && !createdDeals && !currentTab && routerReady) {
      const params = new URLSearchParams({ tab: TabValue.Invited });
      void router.replace(`/deals?${params.toString()}`);
    }
  }, [isSuccess, createdDeals, currentTab, routerReady]);

  return {
    isReady: (currentTab || isSuccess) && routerReady,
    defaultTab: createdDeals.length ? TabValue.Created : TabValue.Invited,
    // TODO: fix once we adapt <StatusBanner /> to be able to display GQL errors
    error: error
      ? {
          error: "We could not display your deals",
          message: "We could not display your deals",
          correlation_id: "graphql_created",
        }
      : null,
  };
}
